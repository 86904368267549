.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: var(--col-grey-100);
    max-height: 100%;
    height: 100%;
}

.dashboard-content {
    flex: 1;
    overflow: auto;
    display: flex;
    align-items: stretch;
}

.day-view {
    display: flex;
    align-items: flex-start;
    gap: var(--u-12);
    overflow: auto;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    /* We can enable scroll snapping, but this might get annoying real fast... */
    /*scroll-snap-type: x proximity;*/
    position: relative;
}

.day-view-content {
    display: flex;
    align-items: flex-start;
    gap: var(--u-12);
    height: auto;
    padding: 0 var(--u-12);
    overflow: visible;
}

.ship-view {
    scroll-snap-align: start;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    gap: var(--u-8);
    min-width: 1250px;
}

.ship-view-title {
    position: sticky;
    top: 0;
    z-index: inherit;
}

.table-single-line {
    table-layout: fixed;
    white-space: nowrap;
}

.table-single-line th,
.table-single-line td {
    overflow: hidden;
}

.topbar button {
    height: 36px;
}